import React, { PureComponent } from "react"
import PropTypes from 'prop-types';
import Img from "gatsby-image"

import './Profile.scss'
import locales from '../../constants/locales'
import BoxRenderer from '../BoxRenderer'

import AngularJS from '../../assets/images/logos/angular-js.png'
import Angular from '../../assets/images/logos/angular.png'
import Atom from '../../assets/images/logos/atom.png'
import BitBucket from '../../assets/images/logos/bitbucket.png'
import Bootstrap from '../../assets/images/logos/bootstrap.png'
import Css3 from '../../assets/images/logos/css-3.png'
import Git from '../../assets/images/logos/git.png'
import GitLab from '../../assets/images/logos/gitlab.png'
import Gulp from '../../assets/images/logos/gulp.png'
import Html5 from '../../assets/images/logos/html-5.png'
import JSLogo from '../../assets/images/logos/js.png'
import Laravel from '../../assets/images/logos/laravel.png'
import PHPLogo from '../../assets/images/logos/php.png'
import ReactLogo from '../../assets/images/logos/react.png'
import Sass from '../../assets/images/logos/sass.png'
import Slack from '../../assets/images/logos/slack.png'
import Slim from '../../assets/images/logos/slim.png'
import SourceTree from '../../assets/images/logos/sourcetree.png'
import Trello from '../../assets/images/logos/trello.png'
import VisualStudioCode from '../../assets/images/logos/visual-studio-code.png'
import VueJS from '../../assets/images/logos/vue-js.png'
import Webpack from '../../assets/images/logos/webpack.png'
import ZendFramework from '../../assets/images/logos/zend-framework.png'
import Zoom from '../../assets/images/logos/zoom.png'

const SeoString = " Fullstack Backend Frontend Web Developer Programming Languages";
const logos = [

    //Frontend
    {
        href: "https://www.w3schools.com/html",
        image: Html5,
        title: "HTML",
        alt: "HTML Hyper Text Markup Language" + SeoString
    }, {
        href: "https://www.w3schools.com/css",
        image: Css3,
        title: "CSS",
        alt: "CSS" + SeoString
    }, {
        href: "https://sass-lang.com",
        image: Sass,
        title: "SASS",
        alt: "SASS SCSS Dynamic CSS" + SeoString
    }, {
        href: "https://getbootstrap.com",
        image: Bootstrap,
        title: "Bootstrap",
        alt: "Bootstrap CSS Library Responsive" + SeoString
    }, {
        href: "https://www.w3schools.com/js",
        image: JSLogo,
        title: "Javascript",
        alt: "Javascript JS ES5/ES6/ES7/ES8" + SeoString
    }, {
        href: "https://angularjs.org",
        image: AngularJS,
        title: "Angular JS",
        alt: "AngularJS" + SeoString
    }, {
        href: "https://angular.io",
        image: Angular,
        title: "Angular 2+",
        alt: "Angular" + SeoString
    }, {
        href: "https://reactjs.org",
        image: ReactLogo,
        title: "React",
        alt: "React JS Framework Frontend" + SeoString
    }, {
        href: "https://vuejs.org",
        image: VueJS,
        title: "VueJS",
        alt: "VueJS JS Framework Frontend" + SeoString
    },

    //Backend
    {
        href: "https://www.w3schools.com/php",
        image: PHPLogo,
        title: "PHP",
        alt: "PHP Backend WebService API REST Server" + SeoString
    }, {
        href: "https://laravel.com",
        image: Laravel,
        title: "Laravel",
        alt: "Laravel Framework PHP Backend WebService API REST Server" + SeoString
    }, {
        href: "http://www.slimframework.com",
        image: Slim,
        title: "Slim",
        alt: "Slim Framework PHP Backend WebService API REST Server" + SeoString
    }, {
        href: "https://framework.zend.com",
        image: ZendFramework,
        title: "ZendFramework",
        alt: "ZendFramework PHP Backend WebService API REST Server" + SeoString
    },

    //Tools
    {
        href: "https://github.com",
        image: Git,
        title: "Git",
        alt: "Git Repository Versioning Tool" + SeoString
    }, {
        href: "https://bitbucket.org",
        image: BitBucket,
        title: "Bitbucket",
        alt: "Bitbucket Repository Versioning Tool" + SeoString
    }, {
        href: "https://gitlab.com",
        image: GitLab,
        title: "GitLab",
        alt: "GitLab Repository Versioning Tool" + SeoString
    }, {
        href: "https://www.sourcetreeapp.com",
        image: SourceTree,
        title: "SourceTree",
        alt: "SourceTree Git Repository Versioning Tool" + SeoString
    }, {
        href: "https://gulpjs.com",
        image: Gulp,
        title: "Gulp",
        alt: "Gulp File Bundler Processor" + SeoString
    }, {
        href: "https://slack.com",
        image: Slack,
        title: "Slack",
        alt: "Slack" + SeoString
    }, {
        href: "https://zoom.us",
        image: Zoom,
        title: "Zoom",
        alt: "Zoom Workflow Teamwork" + SeoString
    }, {
        href: "https://trello.com",
        image: Trello,
        title: "Trello",
        alt: "Trello Task Manager Scrum Workflow Teamwork" + SeoString
    }, {
        href: "https://webpack.js.org",
        image: Webpack,
        title: "Webpack",
        alt: "Webpack JS Bundler Frontend" + SeoString
    },
    
    //Editor
    {
        href: "https://atom.io",
        image: Atom,
        title: "Atom",
        alt: "Atom Editor Programing" + SeoString
    }, {
        href: "https://code.visualstudio.com",
        image: VisualStudioCode,
        title: "VisualStudioCode",
        alt: "VisualStudioCode Editor Programing" + SeoString
    }  
]

class Profile extends PureComponent {
    renderRows() {
        const { informations } = this.props;  
        let items = informations.map((item, index) => {
            return (
                <div key={index} className="RowContainer">
                    <div className="FieldName">{item.field}</div>
                    <div className="FieldValue">{item.value}</div>
                </div>
            )
        })
        return items;
    }

    render() {
        const { image, lang } = this.props;
        const { 
            HELLO,
            IAM
        } = locales[lang].textEntries
        return (
            <>
                <BoxRenderer className="ProfileContainer">
                    <Img fixed={image} className="Image" alt="Nikolas Mosca Fullstack Web Developer"/>
                    <article className="DataContainer">
                        <div className="HelloContainer">{ HELLO }</div>
                        <h2>{ IAM } <strong>Nikolas Mosca</strong></h2>
                        <h1>Fullstack Web Developer | Teacher & Mentor</h1>

                        <div className="ListContainer">
                            {this.renderRows()}
                        </div>
                    </article>
                </BoxRenderer>
                <div className="ProfileLogoContainer">
                    <div>
                        {
                            logos.map(item => {
                                return (
                                    <a href={item.href} key={item.title} target="_blank" rel="noopener noreferrer" title={item.title} alt={item.alt}>
                                        <img src={item.image} title={item.title} alt={item.alt}/>
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }
}

Profile.propTypes = {
    informations: PropTypes.array,
    image: PropTypes.any
}

Profile.defaultProps = {
    informations: [],
    image: {}
}

export default Profile