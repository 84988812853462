import React, { PureComponent } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"
import Img from "gatsby-image"
import { Element } from "react-scroll"
import Topbar from "../components/Topbar"
import Profile from "../components/Profile"
import Button from "../components/Button"
import BoxRenderer from "../components/BoxRenderer"
import BoxExperience from "../components/BoxExperience"
import BoxContact from "../components/BoxContact"
import Privacy from "../components/Privacy"
import ProjectList from "../components/ProjectList"
import "../main.scss"
import locales from "../constants/locales"
import ogImage from "../assets/images/og-logo.jpg"
import Curriculum from "../assets/pdf/cv_mosca_nikolas.pdf"

//https://preview.themeforest.net/item/material-cv-resume-vcard/full_screen_preview/13703959
class Index extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      scrollPosition: 0,
      showSkills: false,
      showWork: false,
      showProjects: false,
      showContact: false,
    }
  }

  componentDidMount() {
    document.addEventListener("scroll", this.listenToScroll, { passive: true })
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.listenToScroll, {
      passive: true,
    })
  }

  listenToScroll = () => {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    let height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    let scrolled = (winScroll / height) * 100

    this.setState({
      scrollPosition: scrolled,
      showSkills:
        this.checkVisible("BackendSkills") ||
        this.checkVisible("FrontendSkills"),
      showWork: this.checkVisible("BoxExperienceContainer"),
      showProjects:
        this.checkVisible("ProjectsTitle") ||
        this.checkVisible("ProjectsContainer"),
      showEducation: this.checkVisible("BoxEducationContainer"),
      showContact: this.checkVisible("BoxContactContainer"),
    })
  }

  checkVisible = name => {
    if (typeof document === "undefined") return false
    let selector = document.getElementsByClassName(name)
    if (!selector || !selector[0]) return false

    let rect = selector[0].getBoundingClientRect()
    let viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    )
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
  }

  calculateAge = () => {
    var msDiff = Date.now() - new Date("1994-09-12").getTime()
    var ageDate = new Date(msDiff)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  renderSkills = filterType => {
    const { showSkills } = this.state
    const skills = [
      { name: "HTML | CSS | SASS", value: "95%", type: ["frontend"] },
      { name: "PHP", value: "95%", type: ["backend"] },
      { name: "DB (Mysql | Postgres | SQL Server)", value: "90%", type: ["backend"] },
      { name: "Laravel", value: "90%", type: ["backend"] },
      { name: "Zend Framework", value: "80%", type: ["backend"] },
      { name: "Slim Framework", value: "80%", type: ["backend"] },
      { name: "Javascript", value: "95%", type: ["frontend", "backend"] },
      { name: "JQuery", value: "90%", type: ["frontend"] },
      { name: "AngularJS", value: "85%", type: ["frontend"] },
      { name: "Angular 2+", value: "45%", type: ["frontend"] },
      { name: "Vue", value: "75%", type: ["frontend"] },
      { name: "React", value: "95%", type: ["frontend"] },
      { name: "React Native", value: "60%", type: ["frontend"] },
      { name: "Node.js", value: "70%", type: ["backend"] },
      { name: "Express", value: "65%", type: ["backend"] },
      { name: "Mongo DB", value: "50%", type: ["backend"] },
      { name: "Docker", value: "60%", type: ["backend"] },
    ]

    return skills.map(({ name, value, type }, index) => {
      if (type.indexOf(filterType) !== -1) {
        return (
          <div key={index} className="Skill">
            <div className="Title">
              <h4>{name}</h4>
            </div>
            <div className="Bar">
              <div
                className="Fill"
                style={{
                  width: showSkills ? value : "0%",
                }}
              ></div>
            </div>
          </div>
        )
      }
      return false
    })
  }

  getMenuVoices = () => {
    const {
      pageContext: { locale },
    } = this.props
    const { ABOUT, SKILLS, EXPERIENCE, PROJECTS, EDUCATION, CONTACT } = locales[
      locale
    ].textEntries
    return [ABOUT, SKILLS, EXPERIENCE, PROJECTS, EDUCATION, CONTACT]
  }

  getMyProjects = () => {
    const {
      data,
      pageContext: { locale },
    } = this.props
    const { MORE_INFORMATIONS, MYPROJECTS_CLICK_HERE } = locales[
      locale
    ].textEntries

    let projects = locales[locale].projects(data)

    let articles = data.projects.nodes.map(
      (
        { frontmatter: { title, date, intro, path, image, language }, html },
        index
      ) => {
        if (language === locale) {
          return {
            key: index,
            years: date.toString(),
            position: MYPROJECTS_CLICK_HERE,
            title,
            text: remark()
              .use(recommended)
              .use(remarkHtml)
              .processSync(intro)
              .toString(),
            url: path,
            img: image.childImageSharp.fluid,
            animated: false,
            button: MORE_INFORMATIONS,
          }
        }
      }
    )

    for (let i = 0; i < articles.length; i++) {
      if (!articles[i]) articles.splice(i, 1)
    }

    projects = projects.concat(articles)

    projects = projects.sort((a, b) => {
      if (a.years > b.years) return -1
      if (a.years < b.years) return 1
      return 0
    })

    for (let i = 0; i < projects.length; i++) {
      if (!projects[i]) projects.splice(i, 1)
    }

    return projects
  }

  render() {
    const {
      data,
      pageContext: { locale },
    } = this.props
    const {
      scrollPosition,
      showWork,
      showProjects,
      showEducation,
      showContact,
    } = this.state
    const {
      TITLE,
      DESCRIPTION,
      KEYWORDS,

      ABOUT,
      SKILLS,
      EXPERIENCE,
      EDUCATION,
      CONTACT,

      AGE,
      ADDRESS,
      EMAIL,
      PHONE,
      FREELANCE,
      FREELANCE_VALUE,

      LINKEDIN,
      PRESENTATION,
      CV,
      BACKEND_SKILLS,
      FRONTEND_SKILLS,
      WORK_EXPERIENCE,

      FREELANCE_DESCRIPTION,
      TEACHER_DESCRIPTION,
      BOOLEAN_POSITION,
      BOOLEAN_DESCRIPTION,
      TDEVS_POSITION,
      TDEVS_DESCRIPTION,
      SHIN_POSITION,
      SHIN_DESCRIPTION,
      WEWELFARE_POSITION,
      WEWELFARE_DESCRIPTION,
      IBAKOO_POSITION,
      IBAKOO_DESCRIPTION,
      WIBO_POSITION,
      WIBO_DESCRIPTION,
      CDA_POSITION,
      CDA_DESCRIPTION,
      FULLSTACKISH_DESCRIPTION,
      DBMEDICA_DESCRIPTION,
      BIDIFFERENT_DESCRIPTION,
      HS_DESCRIPTION,

      DIPLOMA_POSITION,
      DIPLOMA_DESCRIPTION,
      WEBDESIGN_POSITION,
      WEBDESIGN_DESCRIPTION,

      CONTACT_ME,
      MORE_INFORMATIONS,
    } = locales[locale].textEntries

    return (
      <div>
        <Helmet>
          <title>{TITLE}</title>
          <meta name="description" content={DESCRIPTION} />
          <meta name="keywords" content={KEYWORDS} />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="content-language" content={locale} />
          <meta http-equiv="content-type" content="text/html; charset=utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.nks-developer.com/" />
          <meta property="og:title" content={TITLE} />
          <meta property="og:image" content={ogImage} />
          <meta property="og:description" content={DESCRIPTION} />
          <meta
            property="og:locale"
            content={locale + "_" + locale.toUpperCase()}
          />
        </Helmet>
        <Topbar
          scrollPosition={scrollPosition}
          voices={this.getMenuVoices()}
          locale={locale}
        />
        <main>
          <Element name={ABOUT}>
            <div className="ImageBackgroundContainer">
              <Img
                fluid={data.background.childImageSharp.fluid}
                className="ImageBackground"
                alt="Fullstack Web Developer"
              />
            </div>
          </Element>
          <div className="ResponsiveContainer">
            <Profile
              informations={[
                { field: AGE, value: this.calculateAge() },
                {
                  field: ADDRESS,
                  value: "Via cavalieri di Vittorio Veneto, 48",
                },
                { field: EMAIL, value: "mosca.nikolas@gmail.com" },
                { field: PHONE, value: "+39 320 463 2299" },
                { field: FREELANCE, value: FREELANCE_VALUE },
              ]}
              image={data.profile.childImageSharp.fluid}
              lang={locale}
            />

            <Button
              text={LINKEDIN}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/nikolas-mosca-frontend-backend-web-developer",
                  "_blank"
                )
              }
            />
            <h3
              className="LargeText"
              dangerouslySetInnerHTML={{ __html: PRESENTATION }}
            ></h3>
            {/* <Button
              className="DownloadResume"
              text={CV}
              onClick={() => window.open(Curriculum, "_blank")}
            /> */}

            <Element name={SKILLS}>
              <h4 className="TitleText SkillsTitle">{BACKEND_SKILLS}</h4>
            </Element>
            <BoxRenderer className="SkillsContainer BackendSkills">
              {this.renderSkills("backend")}
            </BoxRenderer>

            <h4 className="TitleText SkillsTitle">{FRONTEND_SKILLS}</h4>
            <BoxRenderer className="SkillsContainer FrontendSkills">
              {this.renderSkills("frontend")}
            </BoxRenderer>

            <Element name={EXPERIENCE}>
              <h4 className="TitleText WorkTitle">{WORK_EXPERIENCE}</h4>
            </Element>
            <div className="BoxExperienceContainer">
              <BoxExperience
                years="2016 - NOW"
                title="Freelance"
                position="Fullstack Web Developer"
                className={showWork ? "show" : ""}
                text={FREELANCE_DESCRIPTION}
              >
                <Img
                  fixed={data.mylogo.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2021 - NOW"
                title="Teacher"
                position="Teacher & Mentor"
                className={showWork ? "show" : ""}
                text={TEACHER_DESCRIPTION}
              >
                <Img
                  fixed={data.mylogo2.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2022 - NOW"
                title="Wewelfare"
                position={WEWELFARE_POSITION}
                className={showWork ? "show" : ""}
                text={WEWELFARE_DESCRIPTION}
                moreInformations={MORE_INFORMATIONS}
                onClick={() =>
                  window.open("https://wewelfare.com/", "_blank")
                }
              >
                <Img
                  fixed={data.wewelfare.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2022 - NOW"
                title="SHIN Software"
                position={SHIN_POSITION}
                className={showWork ? "show" : ""}
                text={SHIN_DESCRIPTION}
                moreInformations={MORE_INFORMATIONS}
                onClick={() =>
                  window.open("https://shinsoftware.com/", "_blank")
                }
              >
                <Img
                  fixed={data.shin.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2022 - NOW"
                title="TomorrowDevs"
                position={TDEVS_POSITION}
                className={showWork ? "show" : ""}
                text={TDEVS_DESCRIPTION}
                moreInformations={MORE_INFORMATIONS}
                onClick={() =>
                  window.open("https://www.tomorrowdevs.com/", "_blank")
                }
              >
                <Img
                  fixed={data.tdevs.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2021 - 2022"
                title="Ibakoo"
                position={IBAKOO_POSITION}
                className={showWork ? "show" : ""}
                text={IBAKOO_DESCRIPTION}
                moreInformations={MORE_INFORMATIONS}
                onClick={() =>
                  window.open("https://www.ibakoo.com/", "_blank")
                }
              >
                <Img
                  fixed={data.ibakoo.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2021"
                title="Wibo"
                position={WIBO_POSITION}
                className={showWork ? "show" : ""}
                text={WIBO_DESCRIPTION}
                moreInformations={MORE_INFORMATIONS}
                onClick={() =>
                  window.open("https://wibo.app/", "_blank")
                }
              >
                <Img
                  fixed={data.wibo.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2019 - NOW"
                title="Boolean"
                position={BOOLEAN_POSITION}
                className={showWork ? "show" : ""}
                text={BOOLEAN_DESCRIPTION}
                moreInformations={MORE_INFORMATIONS}
                onClick={() =>
                  window.open("https://www.boolean.careers/", "_blank")
                }
              >
                <Img
                  fixed={data.boolean.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2018 - NOW"
                title="Fullstackish"
                position="Fullstack Web Developer"
                className={showWork ? "show" : ""}
                text={FULLSTACKISH_DESCRIPTION}
              >
                <Img
                  fixed={data.fullstackish.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2017 - 2021"
                title="CDA"
                position={CDA_POSITION}
                className={showWork ? "show" : ""}
                text={CDA_DESCRIPTION}
                moreInformations={MORE_INFORMATIONS}
                onClick={() =>
                  window.open("https://cda-web.net/", "_blank")
                }
              >
                <Img
                  fixed={data.cda.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2016 - 2018"
                title="DBMedica"
                position="Backend Web Developer"
                className={showWork ? "show" : ""}
                text={DBMEDICA_DESCRIPTION}
                moreInformations={MORE_INFORMATIONS}
                onClick={() =>
                  window.open("https://www.dbmedica.it/", "_blank")
                }
              >
                <Img
                  fixed={data.dbmedica.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2014 - 2016"
                title="Bi-Different"
                position="Fullstack Web Developer"
                className={showWork ? "show" : ""}
                text={BIDIFFERENT_DESCRIPTION}
              >
                <Img
                  fixed={data.bidifferent.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
              <BoxExperience
                years="2014"
                title="H&S And LAB080"
                position="Fullstack Web Developer"
                className={showWork ? "show" : ""}
                text={HS_DESCRIPTION}
              >
                <Img
                  fixed={data.hs.childImageSharp.fixed}
                  alt="Nikolas Mosca Fullstack Web Developer"
                />
              </BoxExperience>
            </div>

            <ProjectList
              projects={this.getMyProjects()}
              locale={locales[locale].textEntries}
              show={showProjects}
            />

            <Element name={EDUCATION}>
              <h4 className="TitleText EducationTitle">{EDUCATION}</h4>
            </Element>
            <div className="BoxEducationContainer">
              <BoxExperience
                years="2008 - 2013"
                title="Diploma IT"
                position={DIPLOMA_POSITION}
                className={showEducation ? "show" : ""}
                text={DIPLOMA_DESCRIPTION}
              />
              <BoxExperience
                years="2013 - 2014"
                title="Web Design and Development Course"
                position={WEBDESIGN_POSITION}
                className={showEducation ? "show" : ""}
                text={WEBDESIGN_DESCRIPTION}
              />
            </div>

            <Element name={CONTACT}>
              <h4 className="TitleText ContactTitle">{CONTACT_ME}</h4>
            </Element>
            <BoxContact lang={locale} className={showContact ? "show" : ""} />
          </div>
        </main>

        <Privacy locale={locale}></Privacy>
      </div>
    )
  }
}

export default Index

export const query = graphql`
  query {
    background: file(relativePath: { eq: "background.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 2500
          maxHeight: 550
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    profile: file(relativePath: { eq: "profile-image.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 400
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    mylogo: file(relativePath: { eq: "companies/logo.jpg" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    mylogo2: file(relativePath: { eq: "companies/logo-2.png" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    fullstackish: file(relativePath: { eq: "companies/fullstackish.jpg" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    dbmedica: file(relativePath: { eq: "companies/dbmedica.jpg" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    boolean: file(relativePath: { eq: "companies/boolean.png" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    tdevs: file(relativePath: { eq: "companies/tdevs.jpg" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    shin: file(relativePath: { eq: "companies/shin.png" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    wewelfare: file(relativePath: { eq: "companies/wewelfare.jpg" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    ibakoo: file(relativePath: { eq: "companies/ibakoo.jpg" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    wibo: file(relativePath: { eq: "companies/wibo.png" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    cda: file(relativePath: { eq: "companies/cda.png" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    bidifferent: file(relativePath: { eq: "companies/bidifferent.png" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    hs: file(relativePath: { eq: "companies/hs-vallino-paolo.png" }) {
      childImageSharp {
        fixed(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          width: 128
        ) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    projects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/markdown_projects/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        html
        frontmatter {
          title
          date
          path
          language
          intro
          image {
            childImageSharp {
              fluid(
                traceSVG: {
                  color: "#27292e"
                  turnPolicy: TURNPOLICY_MINORITY
                  blackOnWhite: false
                }
                maxWidth: 1000
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

    tekimaCablesStore: file(relativePath: { eq: "projects/tekima-cables-store.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    tekimaLabelsStore: file(relativePath: { eq: "projects/tekima-labels-store.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    tekimaTubingsStore: file(relativePath: { eq: "projects/tekima-tubings-store.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    idiProject: file(relativePath: { eq: "projects/idiproject.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    museoFumetto: file(relativePath: { eq: "projects/museo-fumetto.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    immobilbiella: file(relativePath: { eq: "projects/immobilbiella.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    docilia: file(relativePath: { eq: "projects/docilia-restauri.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    azimut: file(relativePath: { eq: "projects/azimut.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    nimrod: file(relativePath: { eq: "projects/nimrod.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    tris: file(relativePath: { eq: "projects/tic-tac-toe.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    honda: file(relativePath: { eq: "projects/honda.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    jinni: file(relativePath: { eq: "projects/jinni.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    game2048: file(relativePath: { eq: "projects/2048.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    weatherWebApp: file(relativePath: { eq: "projects/weather-web-app.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    adventOfCode2022: file(relativePath: { eq: "projects/advent-of-code.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
